import React, { useContext, useEffect } from "react"
import { graphql } from "gatsby"
import { ParallaxProvider } from "react-scroll-parallax"

import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../context/GlobalContextProvider"
import socialImage from "../../static/social-share/DMITRIEVA-ART-social-share.jpg"
import SEO from "../components/seo"
import Paintings from "../components/Paintings"

var arraySort = require("array-sort")

const PaintingsPage = ({ data, location }) => {
  const content = data.allPrismicPaintgallery.edges
  const contentOrdered = arraySort(content, "node.data.gallery_year", {
    reverse: true,
  })
  const dispatch = useContext(GlobalDispatchContext)
  const state = useContext(GlobalStateContext)
  const toggleTransition = state.toggleTransition
  const path = location.pathname
  const langSliced = path.slice(1, 3)
  useEffect(() => {
    if (toggleTransition) {
      setTimeout(() => {
        dispatch({ type: "TOGGLE_TRANSITION" })
      }, 600)
    }
  }, [])
  return (
    <>
      <ParallaxProvider>
        <SEO
          title={
            langSliced === "fr"
              ? "Peintures"
              : langSliced === "ru"
              ? "Живопись"
              : "Paintings"
          }
          image={socialImage}
          lang={langSliced}
          path={path}
        />
        <Paintings location={location} slides={contentOrdered} />
      </ParallaxProvider>
    </>
  )
}

export default PaintingsPage

export const query = graphql`
  query PaintingsPage($lang: String!) {
    allPrismicPaintgallery(filter: { lang: { eq: $lang } }) {
      edges {
        node {
          data {
            gallery_year
            gallery_thumbnail {
              fluid {
                src
                ...GatsbyPrismicImageFluid
              }
            }
          }
          prismicId
          lang
        }
      }
    }
  }
`
