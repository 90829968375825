import React, { useContext, useRef } from "react"
import { Link } from "gatsby"
import { navigate } from "gatsby"
import Img from "gatsby-image"

import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../../../../context/GlobalContextProvider"
import "./styles.scss"

const Slide = ({ location, slide, index, current, handleSlideClick }) => {
  const dispatch = useContext(GlobalDispatchContext)
  const path = location.pathname
  const pathSliced = path.slice(1, 3)
  const slideRef = useRef(null)
  const slug = `/paintings/${slide.node.data.gallery_year}`

  const handleMouseMove = event => {
    const el = slideRef.current
    const r = el.getBoundingClientRect()

    el.style.setProperty(
      "--x",
      event.clientX - (r.left + Math.floor(r.width / 2))
    )
    el.style.setProperty(
      "--y",
      event.clientY - (r.top + Math.floor(r.height / 2))
    )
  }

  const handleMouseLeave = event => {
    slideRef.current.style.setProperty("--x", 0)
    slideRef.current.style.setProperty("--y", 0)
  }

  const handleTransition = slug => {
    if (path !== `/${pathSliced}${slug}`) {
      dispatch({ type: "TOGGLE_TRANSITION" })
      setTimeout(() => {
        navigate(`/${pathSliced}${slug}`)
      }, 1000)
    } else {
      return
    }
  }

  let classNames = "slide"

  if (current === index) classNames += " slide--current"
  else if (current - 1 === index) classNames += " slide--previous"
  else if (current + 1 === index) classNames += " slide--next"

  return (
    <li
      ref={slideRef}
      className={classNames}
      onClick={() => {
        handleSlideClick(index)
      }}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
    >
      <div className="slide__image-wrapper">
        <Img
          className="slide__image"
          fluid={slide.node.data.gallery_thumbnail.fluid}
        />
      </div>
      <article className="slide__content">
        <div className="slide__headline">
          <p className="slide__headline-year1">
            {slide.node.data.gallery_year.slice(0, 2)}
          </p>
          <p className="slide__headline-year2">
            {slide.node.data.gallery_year.slice(2, 4)}
          </p>
        </div>
        <Link
          to={slug}
          className="slide__action btn"
          onClick={e => {
            e.preventDefault()
            handleTransition(slug)
          }}
        >
          {pathSliced === "fr"
            ? "Découvrir"
            : pathSliced === "ru"
            ? "посмотреть"
            : "discover"}{" "}
        </Link>
      </article>
    </li>
  )
}

export default Slide
