import React from "react"

import Slider from "./Slider"
import "./styles.scss"

const Paintings = ({ slides, location }) => {
  return (
    <section className="paintings">
      <Slider location={location} slides={slides} />
    </section>
  )
}

export default Paintings
